/**
 * NOTE: Please do not add icons to this file directly. Instead, use the import-icon npm script
 */

/* eslint-disable prettier/prettier */

import * as React from 'react';
import cx from 'classnames';

import { ReactComponent as HelpIcon } from '../../../assets/images/icons/designSystem/helpIcon.svg';
import { ReactComponent as DragIcon } from '../../../assets/images/icons/designSystem/dragIcon.svg';
import { ReactComponent as TickIcon } from '../../../assets/images/icons/designSystem/tickIcon.svg';
import { ReactComponent as DateIcon } from '../../../assets/images/icons/designSystem/dateIcon.svg';
import { ReactComponent as LabelIcon } from '../../../assets/images/icons/designSystem/LabelIcon.svg';
import { ReactComponent as RedoIcon } from '../../../assets/images/icons/designSystem/redoIcon.svg';
import { ReactComponent as UndoIcon } from '../../../assets/images/icons/designSystem/undoIcon.svg';
import { ReactComponent as MinusIcon } from '../../../assets/images/icons/designSystem/minusIcon.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/icons/designSystem/plusIcon.svg';
import { ReactComponent as FreeTextIcon } from '../../../assets/images/icons/designSystem/freeTextIcon.svg';
import { ReactComponent as ChevronRightIcon } from '../../../assets/images/icons/designSystem/chevronRightIcon.svg';
import { ReactComponent as AppleIcon } from '../../../assets/images/icons/apple.svg';
import { ReactComponent as GooglePlayIcon } from '../../../assets/images/icons/googlePlay.svg';
import { ReactComponent as SmsIcon } from '../../../assets/images/icons/sms.svg';
import { ReactComponent as GoogleAuthIcon } from '../../../assets/images/icons/googleAuth.svg';
import { ReactComponent as CheckIcon } from '../../../assets/images/icons/check.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/icons/designSystem/closeIcon.svg';
import { ReactComponent as LockIcon } from '../../../assets/images/icons/designSystem/lock.svg';
import { ReactComponent as PagesIcon } from '../../../assets/images/icons/designSystem/pagesIcon.svg';
import { ReactComponent as SenderPlaceholderIcon } from '../../../assets/images/icons/designSystem/senderPlaceholderIcon.svg';
import { ReactComponent as ChevronDownIcon } from '../../../assets/images/icons/designSystem/chevronDownIcon.svg';
import { ReactComponent as PdfIcon } from '../../../assets/images/icons/designSystem/pdfIcon.svg';
import { ReactComponent as ChevronLeftIcon } from '../../../assets/images/icons/designSystem/chevronLeftIcon.svg';
import { ReactComponent as QuestionMarkIcon } from '../../../assets/images/icons/question.svg';
import { ReactComponent as HolidayHouseIcon } from '../../../assets/images/icons/designSystem/holidayHouseIcon.svg';
import { ReactComponent as WarningIcon } from '../../../assets/images/icons/designSystem/warningIcon.svg';
import { ReactComponent as InactiveCheckboxIcon } from '../../../assets/images/icons/designSystem/inactiveCheckboxIcon.svg';
import { ReactComponent as ActiveCheckboxIcon } from '../../../assets/images/icons/designSystem/activeCheckboxIcon.svg';
import { ReactComponent as ActiveRadioIcon } from '../../../assets/images/icons/designSystem/activeRadioIcon.svg';
import { ReactComponent as InactiveRadioIcon } from '../../../assets/images/icons/designSystem/inactiveRadioIcon.svg';
import { ReactComponent as WitnessSignatureIcon } from '../../../assets/images/icons/designSystem/witnessSignatureIcon.svg';
import { ReactComponent as TrashIcon } from '../../../assets/images/icons/designSystem/trashIcon.svg';
import { ReactComponent as EmailIcon } from '../../../assets/images/icons/designSystem/emailIcon.svg';
import { ReactComponent as SignIcon } from '../../../assets/images/icons/designSystem/signIcon.svg';
import { ReactComponent as SmsDeliveryIcon } from '../../../assets/images/icons/designSystem/smsDeliveryIcon.svg';
import { ReactComponent as EntryDoorIcon } from '../../../assets/images/icons/designSystem/entryDoorIcon.svg';
import { ReactComponent as ActiveRadioDarkIcon } from '../../../assets/images/icons/designSystem/activeRadioDarkIcon.svg';
import { ReactComponent as CalenderIcon } from '../../../assets/images/icons/designSystem/calenderIcon.svg';
import { ReactComponent as InfoIcon } from '../../../assets/images/icons/designSystem/infoIcon.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/images/icons/designSystem/errorIcon.svg';
import { ReactComponent as LinkIcon } from '../../../assets/images/icons/designSystem/linkIcon.svg';
import { ReactComponent as copyIconPrimary } from '../../../assets/images/icons/designSystem/copyPrimaryIcon.svg';
import { ReactComponent as PropertyMeIcon } from '../../../assets/images/icons/designSystem/propertyMeIcon.svg';
import { ReactComponent as PropertyTreeIcon } from '../../../assets/images/icons/designSystem/propertyTreeIcon.svg';
import { ReactComponent as MegaphoneIcon } from '../../../assets/images/icons/designSystem/megaphoneIcon.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/icons/designSystem/editIcon.svg';
import { ReactComponent as UserIcon } from '../../../assets/images/icons/designSystem/userIcon.svg';
import { ReactComponent as EspIcon } from '../../../assets/images/icons/designSystem/espIcon.svg';
import { ReactComponent as NoteErrorIcon } from '../../../assets/images/icons/designSystem/noteErrorIcon.svg';
import { ReactComponent as NoteSuccessIcon } from '../../../assets/images/icons/designSystem/noteSuccessIcon.svg';
import { ReactComponent as ListNowIcon } from '../../../assets/images/icons/designSystem/listNowIcon.svg';
import { ReactComponent as ProductBusinessIcon } from '../../../assets/images/icons/designSystem/productBusinessIcon.svg';
import { ReactComponent as ProductRealEstateIcon } from '../../../assets/images/icons/designSystem/productRealEstateIcon.svg';
import { ReactComponent as FlagAUIcon } from '../../../assets/images/icons/designSystem/flagAUIcon.svg';
import { ReactComponent as FlagNZIcon } from '../../../assets/images/icons/designSystem/flagNZIcon.svg';
import { ReactComponent as QrCodeIcon } from '../../../assets/images/icons/designSystem/qrCodeIcon.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/images/icons/designSystem/downloadIcon.svg';
import { ReactComponent as EmailDeliveryV2Icon } from '../../../assets/images/icons/designSystem/emailDeliveryV2Icon.svg';
import { ReactComponent as SmsDeliveryV2Icon } from '../../../assets/images/icons/designSystem/smsDeliveryV2Icon.svg';
import { ReactComponent as AcknowledgeResponseIcon } from '../../../assets/images/icons/designSystem/acknowledgeResponseIcon.svg';
import { ReactComponent as SignResponseIcon } from '../../../assets/images/icons/designSystem/signResponseIcon.svg';
import { ReactComponent as CopyIcon } from '../../../assets/images/icons/designSystem/copyIcon.svg';
import { ReactComponent as MriLogoIcon } from '../../../assets/images/icons/designSystem/mriLogoIcon.svg';
import { ReactComponent as ManagedLogoIcon } from '../../../assets/images/icons/designSystem/managedLogoIcon.svg';
import { ReactComponent as PropertyMeLogoIcon } from '../../../assets/images/icons/designSystem/propertyMeLogoIcon.svg';
import { ReactComponent as UploadIcon } from '../../../assets/images/icons/designSystem/uploadIcon.svg';
import { ReactComponent as AlertSyncIcon } from '../../../assets/images/icons/designSystem/alertSyncIcon.svg';
import { ReactComponent as PropertyIcon } from '../../../assets/images/icons/designSystem/propertyIcon.svg';
import { ReactComponent as ReapitConsoleLogoIcon } from '../../../assets/images/icons/designSystem/reapitConsoleLogoIcon.svg';
import { ReactComponent as ReUploadIcon } from '../../../assets/images/icons/designSystem/reUploadIcon.svg';
import { ReactComponent as VerificationRequiredIcon } from '../../../assets/images/icons/designSystem/verificationRequiredIcon.svg';
import { ReactComponent as VerificationFailedIcon } from '../../../assets/images/icons/designSystem/verificationFailedIcon.svg';

import styles from './Icon.module.scss';

enum Icons {
    HELP,
    DRAG,
    TICK,
    DATE,
    LABEL,
    REDO,
    UNDO,
    MINUS,
    PLUS,
    FREE_TEXT,
    CHEVRON_RIGHT,
    APPLE,
    GOOGLE_PLAY,
    GOOGLE_AUTH,
    SMS,
    CHECK,
    PAGES,
    CLOSE,
    LOCK,
    SENDER_PLACEHOLDER,
    CHEVRON_DOWN,
    PDF,
    CHEVRON_LEFT,
    QUESTION_MARK,
    HOLIDAY_HOUSE,
    WARNING,
    INACTIVE_CHECKBOX,
    ACTIVE_CHECKBOX,
    ACTIVE_RADIO,
    INACTIVE_RADIO,
    WITNESS_SIGNATURE,
    TRASH,
    EMAIL,
    SIGN,
    SMS_DELIVERY,
    ENTRY_DOOR,
    ACTIVE_RADIO_DARK,
    ICON_CALENDER,
    INFO,
    ERROR,
    LINK,
    COPY_PRIMARY,
    PROPERTY_ME,
    MEGAPHONE,
    PROPERTY_TREE,
    EDIT,
    USER,
    ESP,
    NOTE_ERROR,
    NOTE_SUCCESS,
    LIST_NOW,
    PRODUCT_BUSINESS,
    PRODUCT_REAL_ESTATE,
    FLAG_AU,
    FLAG_NZ,
    QR_CODE,
    DOWNLOAD,
    EMAIL_DELIVERY_V2,
    SMS_DELIVERY_V2,
    ACKNOWLEDGE_RESPONSE,
    SIGN_RESPONSE,
    COPY,
    MRI_LOGO,
    MANAGED_LOGO,
    PROPERTY_ME_LOGO,
    UPLOAD,
    ALERT_SYNC,
    PROPERTY,
    REAPIT_CONSOLE_LOGO,
    RE_UPLOAD,
    VERIFICATION_REQUIRED,
    VERIFICATION_FAILED,
}

enum IconSize {
    DEFAULT,
    SMALL,
    SMALLER,
    LARGE
}

interface IconProps {
    icon: Icons;
    size?: IconSize;
    className?: string;
}

const getIcon = (icon: Icons) => {
    switch (icon) {
        case Icons.HELP:
            return HelpIcon;
        case Icons.DRAG:
            return DragIcon;
        case Icons.TICK:
            return TickIcon;
        case Icons.DATE:
            return DateIcon;
        case Icons.LABEL:
            return LabelIcon;
        case Icons.REDO:
            return RedoIcon;
        case Icons.UNDO:
            return UndoIcon;
        case Icons.MINUS:
            return MinusIcon;
        case Icons.PLUS:
            return PlusIcon;
        case Icons.FREE_TEXT:
            return FreeTextIcon;
        case Icons.CHEVRON_RIGHT:
            return ChevronRightIcon;
        case Icons.APPLE:
            return AppleIcon;
        case Icons.GOOGLE_PLAY:
            return GooglePlayIcon;
        case Icons.GOOGLE_AUTH:
            return GoogleAuthIcon;
        case Icons.SMS:
            return SmsIcon;
        case Icons.CHECK:
            return CheckIcon;
        case Icons.CLOSE:
            return CloseIcon;
        case Icons.LOCK:
            return LockIcon;
        case Icons.PAGES:
            return PagesIcon;
        case Icons.SENDER_PLACEHOLDER:
            return SenderPlaceholderIcon;
        case Icons.CHEVRON_DOWN:
            return ChevronDownIcon;
        case Icons.PDF:
            return PdfIcon;
        case Icons.CHEVRON_LEFT:
            return ChevronLeftIcon;
        case Icons.QUESTION_MARK:
            return QuestionMarkIcon;
        case Icons.HOLIDAY_HOUSE:
            return HolidayHouseIcon;
        case Icons.WARNING:
            return WarningIcon;
        case Icons.INACTIVE_CHECKBOX:
            return InactiveCheckboxIcon;
        case Icons.ACTIVE_CHECKBOX:
            return ActiveCheckboxIcon;
        case Icons.ACTIVE_RADIO:
            return ActiveRadioIcon;
        case Icons.INACTIVE_RADIO:
            return InactiveRadioIcon;
        case Icons.WITNESS_SIGNATURE:
            return WitnessSignatureIcon;
        case Icons.TRASH:
            return TrashIcon;
        case Icons.EMAIL:
            return EmailIcon;
        case Icons.SIGN:
            return SignIcon;
        case Icons.SMS_DELIVERY:
            return SmsDeliveryIcon;
        case Icons.ENTRY_DOOR:
            return EntryDoorIcon;
        case Icons.ACTIVE_RADIO_DARK:
            return ActiveRadioDarkIcon;
        case Icons.ICON_CALENDER:
            return CalenderIcon;
        case Icons.INFO:
            return InfoIcon;
        case Icons.ERROR:
            return ErrorIcon;
        case Icons.LINK:
            return LinkIcon;
        case Icons.COPY_PRIMARY:
            return copyIconPrimary;
        case Icons.PROPERTY_ME:
            return PropertyMeIcon;
        case Icons.MEGAPHONE:
            return MegaphoneIcon;
        case Icons.PROPERTY_TREE:
            return PropertyTreeIcon;
        case Icons.EDIT:
            return EditIcon;
        case Icons.USER:
            return UserIcon;
        case Icons.ESP:
            return EspIcon;
        case Icons.NOTE_ERROR:
            return NoteErrorIcon;
        case Icons.NOTE_SUCCESS:
            return NoteSuccessIcon;
        case Icons.LIST_NOW:
            return ListNowIcon;
        case Icons.PRODUCT_BUSINESS:
            return ProductBusinessIcon;
        case Icons.PRODUCT_REAL_ESTATE:
            return ProductRealEstateIcon;
        case Icons.FLAG_AU:
            return FlagAUIcon;
        case Icons.FLAG_NZ:
            return FlagNZIcon;
        case Icons.QR_CODE:
            return QrCodeIcon;
        case Icons.DOWNLOAD:
            return DownloadIcon;
        case Icons.EMAIL_DELIVERY_V2:
            return EmailDeliveryV2Icon;
        case Icons.SMS_DELIVERY_V2:
            return SmsDeliveryV2Icon;
        case Icons.ACKNOWLEDGE_RESPONSE:
            return AcknowledgeResponseIcon;
        case Icons.SIGN_RESPONSE:
            return SignResponseIcon;
        case Icons.COPY:
            return CopyIcon;
        case Icons.MRI_LOGO:
            return MriLogoIcon;
        case Icons.MANAGED_LOGO:
            return ManagedLogoIcon;
        case Icons.PROPERTY_ME_LOGO:
            return PropertyMeLogoIcon;
        case Icons.UPLOAD:
            return UploadIcon;
        case Icons.ALERT_SYNC:
            return AlertSyncIcon;
        case Icons.PROPERTY:
            return PropertyIcon;
        case Icons.REAPIT_CONSOLE_LOGO:
            return ReapitConsoleLogoIcon;
        case Icons.RE_UPLOAD:
            return ReUploadIcon;
        case Icons.VERIFICATION_REQUIRED:
            return VerificationRequiredIcon;
        case Icons.VERIFICATION_FAILED:
            return VerificationFailedIcon;
        default:
            throw new Error('An invalid icon prop was passed to the Icon component.');
    }
};

const Icon: React.FC<IconProps> = ({ icon, className, size = IconSize.DEFAULT, ...rest }) => {
    const IconComponent = getIcon(icon);

    return (
        <IconComponent
            className={cx(styles.icon, className, {
                [styles.small]: size === IconSize.SMALL,
                [styles.smaller]: size === IconSize.SMALLER,
                [styles.large]: size === IconSize.LARGE
            })}
            {...rest}
        />
    );
};

export default Icon;
export { Icons, IconSize, getIcon };

// fixes esbuild errors
export {
    HelpIcon,
    DragIcon,
    TickIcon,
    DateIcon,
    LabelIcon,
    RedoIcon,
    UndoIcon,
    MinusIcon,
    PlusIcon,
    FreeTextIcon,
    ChevronRightIcon,
    AppleIcon,
    GooglePlayIcon,
    SmsIcon,
    GoogleAuthIcon,
    CheckIcon,
    CloseIcon,
    LockIcon,
    PagesIcon,
    SenderPlaceholderIcon,
    ChevronDownIcon,
    PdfIcon,
    ChevronLeftIcon,
    QuestionMarkIcon,
    HolidayHouseIcon,
    WarningIcon,
    InactiveCheckboxIcon,
    ActiveCheckboxIcon,
    ActiveRadioIcon,
    InactiveRadioIcon,
    WitnessSignatureIcon,
    TrashIcon,
    EmailIcon,
    SignIcon,
    SmsDeliveryIcon,
    EntryDoorIcon,
    ActiveRadioDarkIcon,
    CalenderIcon,
    InfoIcon,
    ErrorIcon,
    LinkIcon,
    copyIconPrimary,
    PropertyMeIcon,
    PropertyTreeIcon,
    MegaphoneIcon,
    EditIcon,
    UserIcon,
    EspIcon,
    NoteErrorIcon,
    NoteSuccessIcon,
    ListNowIcon,
    ProductBusinessIcon,
    ProductRealEstateIcon,
    FlagAUIcon,
    FlagNZIcon,
    QrCodeIcon,
    DownloadIcon,
    EmailDeliveryV2Icon,
    SmsDeliveryV2Icon,
    AcknowledgeResponseIcon,
    SignResponseIcon,
    CopyIcon,
    MriLogoIcon,
    ManagedLogoIcon,
    PropertyMeLogoIcon,
    UploadIcon,
    AlertSyncIcon,
    PropertyIcon,
    ReapitConsoleLogoIcon,
    ReUploadIcon,
    VerificationRequiredIcon,
    VerificationFailedIcon,
};
