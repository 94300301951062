export const BUSINESS_MOBILE_MESSAGE = 'businessMobileMessage';
export const IDENTIFICATION_IMAGES = 'identificationImages';
export const MULTI_FACTOR_AUTHENTICATION = 'multiFactorAuthentication';
export const DOC_COMPLETION_EMAIL_SEND_LIST = 'documentCompletionSendList';
export const COMPLIANCE_CHECKLIST = 'complianceChecklist';
export const INTEGRATION_INSPECT_REAL_ESTATE = 'inspectRealEstateIntegration';
export const PM_SA = 'pmSa';
export const ONLY_ALLOW_FREE_TEXT_RESIZING = 'onlyAllowFreeTextResizing';
export const NSW_HOLIDAY_LETTING = 'nswHolidayLetting';
export const NSW_COMMERCIAL_PROPERTY_MANAGEMENT = 'NSW_Commercial_Property_Management';
export const BUSINESS_GLOBAL_BANNER = 'BusinessGlobalBanner';
export const ENFORCE_SIGNING_ORDER = 'Enforce_Signing_Order';
export const BUILD_A_DOC_V2 = 'buildADocV2';
export const NSW_ENTRY_NOTICE = 'nswEntryNotice';
export const QLD_ENTRY_NOTICE = 'QLDEntryNotice';
export const QLD_BREACH_NOTICE = 'QLD_Breach_Notice';
export const COMPLETION_LIST_V2 = 'completionListV2';
export const UPLOAD_A_DOC_RESPONSIVE = 'uploadADocResponsive';
export const SUGGESTIONS = 'suggestions';
export const SUGGESTIONS_LANDLORD_CONTACT_DETAILS_PROPERTY_ME = 'suggestionsContactDetailsPropertyMe';
export const SUGGESTIONS_LANDLORD_INFORMATION_STATEMENT = 'suggestionsLandlordInformationStatement';
export const RTA_QLD_CORPORATION_LESSOR = 'rtaQldCorporationLessor';
export const FLK_B_MOBILE_RESPONSIVE = 'flkBMobileResponsive';
export const NSW_INTENTION_TO_SELL = 'NSW_Intention_to_Sell';
export const IMPROVE_ANNOTATION_ACCURACY = 'improveAnnotationAccuracy';
export const UPLOAD_A_DOC_INTEGRATION = 'uploadADocIntegration';
export const ANNOTATION_SELECTION_BOX_V2 = 'annotationSelectionBoxV2';
export const DOCUMENTS_DROPDOWN = 'documentsDropdown';
export const TEMPLATE_SCREEN_V2 = 'templateScreenV2';
export const FLK_RE_SIDEBAR_TEMPLATE_BUTTON = 'flkReSidebarTemplateButton';
export const ALL_DOCUMENTS_ALL_AGENCIES_REPORT = 'allDocumentsAllAgenciesReport';
export const SIGNER_PLACEHOLDERS_DEMO = 'signerPlaceholdersDemo';
export const DECREASE_FREE_TEXT_DEFAULT_FONT_SIZE = 'decreaseFreeTextDefaultFontSize';
export const UAD_GLOBAL_TEMPLATES = 'uadGlobalTemplates';
export const HUBSPOT = 'hubspot';
export const CHAT_GPT_INTEGRATION = 'chatGptIntegration';
export const NSW_ESTIMATED_SELLING_PRICE_NOTICE = 'NSWEstimatedSellingPriceNotice';
export const FLK_API_INTEGRATION = 'flkApiIntegration';
export const ANNOTATION_RESIZING_FIX = 'annotationResizingFix';
export const SMARTLOOK = 'smartlook';
export const RTA_PROPERTY_TREE_SYNC = 'Is_RTA_Property_Tree_Sync_Enabled';
export const SEQUENTIAL_SIGNING = 'sequentialSigning';
export const NZ_SIGNUP = 'NZSignup';
export const LIST_NOW = 'listNow';
export const CONTACT_LIST_SETTINGS = 'contactListSettings';
export const RTA_OWNER_COPY = 'rtaOwnerCopy';
export const MULTIPLE_RECIPIENTS_CLIENT_PLACEHOLDERS = 'multipleRecipientsClientPlaceholders';
export const BULK_PM_IMPORT = 'BulkPMImport';
export const EMAIL_STUDIO = 'emailStudio';
export const COPY_DOCUMENT_SIGNING_LINKS = 'copyDocumentSigningLinks';
export const ACCOUNT_PROVIDER_FIX = 'accountProviderFix';
export const QR_CODE_SHARING = 'qrCodeSharing';
export const ALLOW_SENDER_PLACEHOLDER_RESIZING = 'allowSenderPlaceholderResizing';
export const CONSOLE_INTEGRATION = 'IntegrationReapItConsole';
export const VAULT_RE_INTEGRATION = 'vaultReIntegration';
export const UAD_DOCUMENT_REORDERING = 'uadDocumentReordering';
export const DEFAULT_UTILITY_SERVICES = 'defaultUtilityServices';
export const CONSOLE_INTEGRATION_TERMINATION_NOTICE = 'ReapitConsoleTerminationNotice';
export const CONSOLE_INTEGRATION_INTENTION_TO_SELL_NOTICE = 'IntentionToSellReapItConsoleIntegration';
export const DAGOBAH_QLD_RPMA = 'Dagobah_QLD_RPMA';
export const UAD_CONNECT_ID = 'uadConnectId';
export const CONSOLE_INTEGRATION_RENT_INCREASE = 'RentIncreaseReapConsoleIntegration';
export const CONSOLE_INTEGRATION_ENTRY_NOTICE = 'EntryNoticeReapitConsoleIntegration';
export const CONSOLE_INTEGRATION_BREACH_NOTICE = 'BreachNoticeReapItConsoleIntegration';
