import { upperFirst } from 'lodash';

// Lease agreement available types.
export const LEASE_TYPE_RESIDENTIAL = 'residential_tenancy';
export const LEASE_TYPE_PRIVATE_TREATY = 'private_treaty';
export const LEASE_TYPE_PROPERTY_MANAGEMENT = 'property_management';
export const LEASE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT = 'commercial_property_management';
export const LEASE_TYPE_AUCTION_AGREEMENTS = 'auction_agreements';
export const LEASE_TYPE_SALES = 'sales';
export const LEASE_TYPE_COMMERCIAL_LEASE = 'commercial_lease';
export const LEASE_TYPE_HOLIDAY_LETTING = 'holiday_letting';

export const SUB_LEASE_TYPE_SA_RENEWALS = 'sa_renewals';
export const SUB_LEASE_TYPE_LONG_TERM_RENEWAL = 'long_term_renewal';
export const SUB_LEASE_TYPE_SHORT_TERM_RENEWAL = 'short_term_renewal';
export const DEFAULT_SUB_LEASE_TYPE = 'default';

export const REQUEST_TO_CHANGE_STATUS_NEW = 'new';
export const REQUEST_TO_CHANGE_STATUS_RESOLVED = 'resolved';
export const REQUEST_TO_CHANGE_STATUS_UNRESOLVED = 'unresolved';

// available document types
export const DOCUMENT_TERMINATION_NOTICE = 'termination_notice';
export const DOCUMENT_RENT_REDUCTION = 'rent_reduction_agreement';
export const DOCUMENT_RENT_INCREASE = 'rent_increase_agreement';
export const DOCUMENT_CUSTOM = 'document_custom';
export const DISCLOSURE_DOCUMENT = 'disclosure_document';
export const ALL = 'all';
export const DOCUMENT_CREATE_A_FLK = 'create_a_flk';
export const BREACH_NOTICE = 'breach_notice';
export const ENTRY_NOTICE = 'entry_notice';
export const DOCUMENT_INTENTION_TO_SELL = 'intention_to_sell_notice';
export const DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE = 'estimated_selling_price_notice';

//available question types
export const DISCLOSURE_DOCUMENT_QUESTION = 'disclosure';

export const DOCUMENT_CREATE_MODE = 'DOCUMENT_CREATE_MODE';
export const DOCUMENT_READ_ONLY_MODE = 'DOCUMENT_READ_ONLY_MODE';
export const DOCUMENT_EDIT_MODE = 'DOCUMENT_EDIT_MODE';
export const DOCUMENT_TEMPLATE_MODE = 'DOCUMENT_TEMPLATE_MODE';
export const DOCUMENT_TEMPLATE_READ_ONLY_MODE = 'DOCUMENT_TEMPLATE_READ_ONLY_MODE';

export const validDocumentTypes = [
    DOCUMENT_TERMINATION_NOTICE,
    ENTRY_NOTICE,
    DOCUMENT_RENT_REDUCTION,
    DOCUMENT_RENT_INCREASE,
    DOCUMENT_INTENTION_TO_SELL,
    DOCUMENT_CUSTOM
] as const;

export type ValidDocumentTypes = (typeof validDocumentTypes)[number];

//Template types
export const TEMPLATE_TYPE_PROPERTY_MANAGEMENT = 'property_management_template';
export const TEMPLATE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT = 'commercial_property_management_template';
export const TEMPLATE_TYPE_HOLIDAY_LETTING = 'holiday_letting_template';
export const TEMPLATE_TYPE_SALES = 'sales_template';
export const TEMPLATE_TYPE_RESIDENTIAL_TENANCY = 'residential_tenancy_template';
export const TEMPLATE_TYPE_RENT_RELIEF = 'rent_relief_template';
export const TEMPLATE = 'template';
export const CREATE_A_FLK_TEMPLATE = 'create_a_flk_template';
export const CREATE_A_FLK_GLOBAL_TEMPLATE = 'create_a_flk_global_template';
export const FLK_A_PDF_TEMPLATE = 'flk_a_pdf_template';
export const FLK_A_PDF_GLOBAL_TEMPLATE = 'flk_a_pdf_global_template';

export const LEASE_TYPE_RESIDENTIAL_DISPLAY = 'Residential Tenancy';
export const LEASE_TYPE_PROPERTY_MANAGEMENT_DISPLAY = 'Property Management';
export const LEASE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT_DISPLAY = 'Commercial Property Management';
export const LEASE_TYPE_SALES_DISPLAY = 'Sales';
export const LEASE_TYPE_COMMERCIAL_LEASE_DISPLAY = 'Commercial Lease';
export const LEASE_TYPE_HOLIDAY_LETTING_DISPLAY = 'Holiday Letting';
export const CREATE_A_FLK_DISPLAY = 'Build a doc';
export const CREATE_A_FLK_GLOBAL_DISPLAY = 'Global Build a doc';
export const FLK_A_PDF_DISPLAY = 'Upload a doc';
export const FLK_A_PDF_CREATION_MOBILE_DISPLAY = 'Use a template';
export const FLK_A_PDF_CREATION_DISPLAY = 'Upload one or multiple documents for signing';
export const FLK_A_PDF_CREATION_TEMPLATE_DISPLAY = 'Upload one or multiple documents to template';

export const LANDLORD_INFORMATION_STATEMENT_STATUS_NOT_SENT = 'not-sent';
export const LANDLORD_INFORMATION_STATEMENT_READY_TO_BE_SENT = 'ready-to-be-sent';
export const LANDLORD_INFORMATION_STATEMENT_STATUS_SEND_FAIL = 'send-fail';
export const LANDLORD_INFORMATION_STATEMENT_STATUS_SENT = 'sent';
export const LANDLORD_INFORMATION_STATEMENT_STATUS_CONFIRMED = 'confirmed';

export const LANDLORD_INFORMATION_STATEMENT_SEND_METHOD_SMS = 'sms';
export const LANDLORD_INFORMATION_STATEMENT_SEND_METHOD_EMAIL = 'email';

export const PERIOD_DAY = 'day';
export const PERIOD_WEEK = 'week';
export const PERIOD_FORTNIGHT = 'fortnight';
export const PERIOD_MONTH = 'month';
export const PERIOD_YEAR = 'year';
export const PERIOD_CALENDAR_MONTH = 'calendar_month';
export const PERIOD_ANNUM = 'annum';

export const PERIOD_WEEKLY = 'weekly';
export const PERIOD_FORTNIGHTLY = 'fortnightly';
export const PERIOD_MONTHLY = 'monthly';
export const PERIOD_YEARLY = 'yearly';
export const PERIOD_QUARTERLY = 'quarterly';
export const PERIOD_ANNUALLY = 'annually';

export const DEFAULT_PAGE = 1;
export const DEFAULT_RECORDS_PER_PAGE = 9;

export const formatPeriod = period => {
    if (period === PERIOD_WEEKLY) {
        return PERIOD_WEEK;
    } else if (period === PERIOD_FORTNIGHTLY) {
        return PERIOD_FORTNIGHT;
    } else if (period === PERIOD_MONTHLY) {
        return PERIOD_MONTH;
    }
    return null;
};

export const formatPeriodSingular = period => {
    if (period === PERIOD_WEEK) {
        return PERIOD_WEEK;
    } else if (period === PERIOD_FORTNIGHT) {
        return PERIOD_FORTNIGHT;
    } else if (period === PERIOD_MONTH) {
        return PERIOD_MONTH;
    }
    return null;
};

export const QLD_STATE = 'QLD';
export const NSW_STATE = 'NSW';
export const VIC_STATE = 'VIC';
export const ACT_STATE = 'ACT';
export const NT_STATE = 'NT';
export const SA_STATE = 'SA';
export const TAS_STATE = 'TAS';
export const WA_STATE = 'WA';

export const STANDARD_DATE_FORMAT = 'dd MMMM yyyy'; // 12 January 2017
export const STANDARD_DATE_TIME_FORMAT_DATE = "dd MMMM yyyy, h:mm aaaaa'm'";
export const STANDARD_DATE_AUSTRALIAN_FORMAT = 'dd/MM/yyyy';
export const STANDARD_DATE_TIME_AUSTRALIAN_FORMAT_DATE = "dd/MM/yyyy, h:mm aaaaa'm'";
export const STANDARD_DATE_AUSTRALIAN_FORMAT_PLACEHOLDER = 'dd/mm/YYYY';
export const STANDARD_DATE_AMERICAN_FORMAT = 'yyyy-MM-dd'; // 2017-11-15
export const STANDARD_DATE_DAY_FORMAT_DATE = 'd MMMM yyyy';
export const STANDARD_DATE_MONTH_FORMAT_DATE = 'dd MMM yyyy';
export const STANDARD_DATE_DAY_FORMAT_WITH_TH = 'do MMMM yyyy';
export const DATE_MONTH_FIRST_FORMAT_DATE = 'MMMM dd yyyy';
export const STANDARD_DATE_MONTH_FORMAT = 'do MMMM';
export const FORMAT_TIME_ONLY = 'h:mm a';
export const STANDARD_DATE_TIME_FORMAT_WITH_AT = "d MMMM yyyy 'at' h:mma";

export const TIMEZONE_QLD_STATE = 'Australia/Brisbane';
export const TIMEZONE_NSW_STATE = 'Australia/Sydney';
export const TIMEZONE_VIC_STATE = 'Australia/Melbourne';
export const TIMEZONE_ACT_STATE = 'Australia/Sydney';
export const TIMEZONE_NT_STATE = 'Australia/Darwin';
export const TIMEZONE_SA_STATE = 'Australia/Adelaide';
export const TIMEZONE_TAS_STATE = 'Australia/Hobart';
export const TIMEZONE_WA_STATE = 'Australia/Perth';
export const TIMEZONE_LOADING = 'loading_timezone';

export const TIMEZONE_NZ_AUCKLAND = 'Pacific/Auckland';
export const TIMEZONE_NZ_CHATHAM = 'Pacific/Chatham';

export const VACANT = 'Vacant possession';
export const EXISTING_TENANCY = 'Subject to existing tenancy';

export const INCLUDING_GST = 'including GST';
export const INCL_GST = 'incl. GST';
export const PLUS_GST = '+ GST';
export const EXCL_GST = 'excl. GST';

// PM agreement terms constants
export const SERVICE_TYPE_LETTING = 'Letting / Collection Of Rent / Management';
export const SERVICE_TYPE_SALE = 'Sale';
export const SERVICE_TYPE_AUCTION = 'Auction';

export const TERM_TYPE_SINGLE = 'Single Appointment';
export const TERM_TYPE_CONTINUING = 'Continuing Appointment';

export const BASIS_TYPE_OPEN_LISTING = 'Open Listing';
export const BASIS_TYPE_SOLE_AGENCY = 'Sole Agency';
export const BASIS_TYPE_EXCLUSIVE_AGENCY = 'Exclusive Agency';

export const SETTLEMENT_TYPE_CASH = 'Cash';
export const SETTLEMENT_TYPE_OTHER = 'Other';

export const LABEL_AGREE = 'Agree';
export const LABEL_DO_NOT_AGREE = 'Do not agree';

// PM agreement pool safety and smoke alerms constants
export const POOL_TYPE_REGULATED_POOL_SHARED = 'Regulated Pool (shared)';
export const POOL_TYPE_REGULATED_POOL_NOT_SHARED = 'Regulated Pool (not shared)';
export const POOL_TYPE_NO_POOL = 'No Pool but plan to erect a portable pool with depth of 300mm or greater';

export const SMOKE_ALARM_TYPE_COMPLIANT = 'Compliant';
export const SMOKE_ALARM_TYPE_NON_COMPLIANT = 'Non Compliant';
export const SMOKE_ALARM_TYPE_UNKNOWN = 'Unknown';

export const SMOKE_ALARM_MAINTAIN_LANDLORD = 'Landlord';
export const SMOKE_ALARM_MAINTAIN_LESSOR = 'Lessor';
export const SMOKE_ALARM_MAINTAIN_AGENT = 'Agent';
export const SMOKE_ALARM_MAINTAIN_STRATA = 'Strata';
export const SMOKE_ALARM_MAINTAIN_COMPANY = 'Company utilized by the agent for Smoke Alarms';

export const SMOKE_ALARM_ARRANGED_PRIOR = 'SMOKE_ALARM_ARRANGED_PRIOR';
export const SMOKE_ALARM_ALREADY_COMPLETED = 'SMOKE_ALARM_ALREADY_COMPLETED';
export const SMOKE_ALARM_AS_SPECIFIED = 'SMOKE_ALARM_AS_SPECIFIED';

export const YES_VALUE = 'yes';
export const NO_VALUE = 'no';
export const YES_LABEL = 'Yes';
export const NO_LABEL = 'No';
export const NA_LABEL = 'N/A';

export const AGENT_LABEL = 'Agent';
export const LANDLORD_LABEL = 'Landlord';

export const HIDE_MESSAGE_TIME = 5000;
export const DEBOUNCE_TIME = 200;
export const PDF_GENERATE_WAIT_TIME = 8000;
export const MOBILE_WIDTH = 991;
export const MOBILE_WIDTH_SMALL = 767;

export const COMMISSION_TYPE_FIXED = 'Fixed';
export const COMMISSION_TYPE_PERCENTAGE = 'Percentage';

export const PRICE_TYPE_RESERVE = 'Reserve';
export const PRICE_TYPE_LIST = 'List';

export const COMMISSION_PAYABLE_TYPE_OTHER = 'Other';
export const COMMISSION_PAYABLE_TYPE_SALES = 'Sales';

export const gstList = [
    { value: INCLUDING_GST, label: INCLUDING_GST },
    { value: PLUS_GST, label: PLUS_GST }
];
export const qldGstList = [
    { value: INCL_GST, label: INCL_GST },
    { value: PLUS_GST, label: PLUS_GST },
    { value: EXCL_GST, label: EXCL_GST }
];
export const pmVicDurationList = [
    { value: PERIOD_WEEK, label: 'Week' },
    { value: PERIOD_CALENDAR_MONTH, label: 'Calendar month' },
    { value: PERIOD_ANNUM, label: 'Annum' }
];

export const availableStates = {
    sales: { states: [NSW_STATE, QLD_STATE] },
    residential_tenancy: { states: [NSW_STATE, QLD_STATE, VIC_STATE, ACT_STATE, SA_STATE] },
    property_management: { states: [NSW_STATE, QLD_STATE, VIC_STATE, SA_STATE] },
    commercial_property_management: { states: [NSW_STATE] },
    commercial_lease: { states: [NSW_STATE] },
    holiday_letting: { states: [NSW_STATE] },
    property_management_template: { states: [NSW_STATE, QLD_STATE, VIC_STATE, SA_STATE] },
    sales_template: { states: [NSW_STATE, QLD_STATE] },
    rent_increase_agreement: { states: [NSW_STATE, QLD_STATE] },
    termination_notice: { states: [NSW_STATE, QLD_STATE] },
    disclosure_document: { states: [VIC_STATE] },
    breach_notice: { states: [NSW_STATE, QLD_STATE] },
    sa_renewals: { states: [SA_STATE] },
    entry_notice: { states: [NSW_STATE, QLD_STATE] },
    default: { states: [NSW_STATE, QLD_STATE, VIC_STATE, ACT_STATE, NT_STATE, SA_STATE, TAS_STATE, WA_STATE] },
    intention_to_sell_notice: { states: [NSW_STATE] },
    estimated_selling_price_notice: { states: [NSW_STATE] }
};

export const availableStatesForTerminationNoticeWithoutLease = [
    { value: NSW_STATE, label: NSW_STATE },
    { value: QLD_STATE, label: QLD_STATE }
];

export const rentPeriods = [
    { value: PERIOD_WEEKLY, label: 'Weekly' },
    { value: PERIOD_FORTNIGHTLY, label: 'Fortnightly' },
    { value: PERIOD_MONTHLY, label: 'Monthly' }
];

export const commercialLeaseRentPeriods = [
    { value: PERIOD_WEEKLY, label: 'Weekly' },
    { value: PERIOD_FORTNIGHTLY, label: 'Fortnightly' },
    { value: PERIOD_MONTHLY, label: 'Monthly' },
    { value: PERIOD_ANNUM, label: 'Annum' }
];

export const rebatePeriods = [
    { value: PERIOD_WEEKLY, label: 'Weekly' },
    { value: PERIOD_FORTNIGHTLY, label: 'Fortnightly' },
    { value: PERIOD_MONTHLY, label: 'Monthly' },
    { value: PERIOD_YEARLY, label: 'Yearly' }
];

export const rentPeriodsSingular = [
    { value: PERIOD_WEEK, label: upperFirst(PERIOD_WEEK) },
    { value: PERIOD_FORTNIGHT, label: upperFirst(PERIOD_FORTNIGHT) },
    { value: PERIOD_MONTH, label: upperFirst(PERIOD_MONTH) }
];

export const PAYMENT_METHOD_DIRECT_DEBIT = 'DirectDebit';
export const PAYMENT_METHOD_EFT = 'EFT';
export const PAYMENT_METHOD_BPAY = 'BPAY';
export const PAYMENT_METHOD_CHEQUE = 'Cheque';
export const PAYMENT_METHOD_CASH = 'Cash';
export const PAYMENT_METHOD_OTHER = 'Other';
export const PAYMENT_METHOD_MONEY_ORDER = 'MoneyOrder';
export const PAYMENT_METHOD_OWNER_TO_ENTER = 'OwnerToEnter';

export const availablePaymentMethods = {
    sales: { methods: [] },
    residential_tenancy: {
        [NSW_STATE]: {
            methods: [
                PAYMENT_METHOD_DIRECT_DEBIT,
                PAYMENT_METHOD_EFT,
                PAYMENT_METHOD_BPAY,
                PAYMENT_METHOD_CHEQUE,
                PAYMENT_METHOD_CASH,
                PAYMENT_METHOD_OTHER
            ]
        },
        [QLD_STATE]: {
            methods: [
                PAYMENT_METHOD_DIRECT_DEBIT,
                PAYMENT_METHOD_EFT,
                PAYMENT_METHOD_BPAY,
                PAYMENT_METHOD_CHEQUE,
                PAYMENT_METHOD_CASH,
                PAYMENT_METHOD_OTHER
            ]
        },
        [VIC_STATE]: {
            methods: [
                PAYMENT_METHOD_DIRECT_DEBIT,
                PAYMENT_METHOD_EFT,
                PAYMENT_METHOD_BPAY,
                PAYMENT_METHOD_CHEQUE,
                PAYMENT_METHOD_CASH,
                PAYMENT_METHOD_MONEY_ORDER,
                PAYMENT_METHOD_OTHER
            ]
        },
        [ACT_STATE]: {
            methods: [
                PAYMENT_METHOD_DIRECT_DEBIT,
                PAYMENT_METHOD_EFT,
                PAYMENT_METHOD_BPAY,
                PAYMENT_METHOD_CHEQUE,
                PAYMENT_METHOD_CASH,
                PAYMENT_METHOD_OTHER
            ]
        },
        [SA_STATE]: {
            methods: [
                PAYMENT_METHOD_DIRECT_DEBIT,
                PAYMENT_METHOD_EFT,
                PAYMENT_METHOD_BPAY,
                PAYMENT_METHOD_CHEQUE,
                PAYMENT_METHOD_CASH,
                PAYMENT_METHOD_OTHER
            ]
        }
    },
    property_management: {
        [NSW_STATE]: { methods: [PAYMENT_METHOD_OWNER_TO_ENTER, PAYMENT_METHOD_EFT, PAYMENT_METHOD_CHEQUE] },
        [QLD_STATE]: { methods: [PAYMENT_METHOD_OWNER_TO_ENTER, PAYMENT_METHOD_EFT, PAYMENT_METHOD_CHEQUE] },
        [VIC_STATE]: { methods: [PAYMENT_METHOD_OWNER_TO_ENTER, PAYMENT_METHOD_EFT] },
        [SA_STATE]: { methods: [PAYMENT_METHOD_EFT, PAYMENT_METHOD_CHEQUE, PAYMENT_METHOD_OWNER_TO_ENTER] }
    },
    property_management_template: {
        [NSW_STATE]: { methods: [PAYMENT_METHOD_OWNER_TO_ENTER, PAYMENT_METHOD_EFT, PAYMENT_METHOD_CHEQUE] },
        [QLD_STATE]: { methods: [PAYMENT_METHOD_OWNER_TO_ENTER, PAYMENT_METHOD_EFT, PAYMENT_METHOD_CHEQUE] },
        [VIC_STATE]: { methods: [PAYMENT_METHOD_OWNER_TO_ENTER, PAYMENT_METHOD_EFT] },
        [SA_STATE]: { methods: [PAYMENT_METHOD_EFT, PAYMENT_METHOD_CHEQUE, PAYMENT_METHOD_OWNER_TO_ENTER] }
    }
};

export const salesTermPeriods = [
    { value: PERIOD_DAY, label: 'days' },
    { value: PERIOD_WEEK, label: 'weeks' },
    { value: PERIOD_MONTH, label: 'months' }
];

export const termPeriods = [
    { value: PERIOD_WEEK, label: 'weeks' },
    { value: PERIOD_MONTH, label: 'months' },
    { value: PERIOD_YEAR, label: 'year(s)' }
];

export const termPeriodsWithDays = [
    { value: PERIOD_DAY, label: 'days' },
    { value: PERIOD_WEEK, label: 'weeks' },
    { value: PERIOD_MONTH, label: 'months' },
    { value: PERIOD_YEAR, label: 'year(s)' }
];

export const propertyManagementRentPeriods = [
    { value: PERIOD_WEEK, label: 'Week', numberOfWeeks: 1 },
    { value: PERIOD_MONTH, label: 'Month', numberOfWeeks: 4 }
];
export const propertyManagementRentPeriodsQLD = [
    { value: 'Monthly', label: 'Monthly' },
    { value: 'Twice monthly', label: 'Twice monthly' },
    { value: 'Other', label: 'Other' }
];
export const userRoles = {
    ROLE_AGENCY_TEAMMATE: 'agency-teammate',
    ROLE_AGENCY_PRINCIPAL: 'agency-principal',
    ROLE_AGENCY_ADMIN: 'admin'
};

export const salesOccupation = [
    { value: VACANT, label: VACANT },
    { value: EXISTING_TENANCY, label: EXISTING_TENANCY }
];

// Routes

export const routes = {
    ROUTE_PROFILE: '/user/profile',
    ROUTE_AGENCY_SETTINGS: '/user/account',
    ROUTE_AGENCY_DETAILS: '/user/details',
    ROUTE_AGENCY_DEFAULTS: '/user/defaults',
    ROUTE_TEAM: '/user/team',
    ROUTE_SUBSCRIPTION: '/user/subscribe',
    ROUTE_EMERGENCY_CONTACTS: '/user/contacts',
    ROUTE_SIGNATURES: '/user/signatures',
    ROUTE_SPECIAL_CONDITIONS_TEMPLATES: '/user/conditions',
    ROUTE_AGREEMENTS_TEMPLATES: '/user/agreement-templates',
    ROUTE_CREATE_A_FLK_TEMPLATES: '/user/build-a-doc-templates',
    ROUTE_FLK_A_PDF_TEMPLATES: '/user/upload-a-doc-templates',
    ROUTE_CUSTOMER_SUPPORT: '/user/support',
    ROUTE_REPORTS: '/user/reports',
    ROUTE_AGENCY_REPORTS: '/user/agency/reports',
    ROUTE_AGENT_REPORTS: '/user/agent/:id/report',
    ROUTE_STATISTICS: '/user/statistics',
    ROUTE_FAQ: '/user/faq',
    ROUTE_INTEGRATIONS: '/user/integrations',
    ROUTE_ALL_DOCUMENTS: '/user/all-documents',
    ROUTE_PAYMENTS: '/user/payments',
    ROUTE_DOCUMENT_LINKS: '/user/document-links',
    ROUTE_PM_FEES: '/user/fees/property-management',
    ROUTE_CONTACTS: '/user/user-contacts',
    ROUTE_SECURITY: '/user/security',
    ROUTE_TEMPLATES: '/templates',
    ROUTE_BULK_IMPORT: '/user/bulk-import',
    ROUTE_EMAIL_STUDIO: '/user/email-studio'
};

// Tabs

export const tabs = {
    TAB_PROFILE: 'profile',
    TAB_AGENCY_SETTINGS: 'account',
    TAB_AGENCY_DETAILS: 'details',
    TAB_TEAM: 'team',
    TAB_SUBSCRIPTION: 'subscribe',
    TAB_EMERGENCY_CONTACTS: 'contacts',
    TAB_SIGNATURES: 'signatures',
    TAB_TEMPLATES: 'conditions',
    TAB_CUSTOMER_SUPPORT: 'support',
    TAB_FAQ: 'faq',
    TAB_INTEGRATIONS: 'integrations',
    TAB_PAYMENTS: 'TAB_PAYMENTS',
    TAB_PM_FEES: 'TAB_PM_FEES'
};

export const upgradeSubscriptionModalStates = {
    CONFIRM_UPGRADE: 'CONFIRM_UPGRADE',
    UPGRADE_SUCCESSFUL: 'UPGRADE_SUCCESSFUL',
    UPGRADE_FAILED: 'UPGRADE_FAILED',
    CANCELLED_PLAN: 'CANCELLED_PLAN'
};

export const subscriptionStatus = {
    TRIALING: 'trialing',
    CANCELLING: 'cancelling',
    CANCELED: 'canceled',
    INCOMPLETE: 'incomplete',
    INCOMPLETE_EXPIRED: 'incomplete_expired',
    PAST_DUE: 'past_due',
    UNPAID: 'unpaid',
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    PAUSED: 'paused'
};

export const subscriptionTitle = {
    FREE: 'Free',
    LARGE: 'Large',
    LIST_NOW: 'List Now'
};

export const documentCategories = {
    [LEASE_TYPE_RESIDENTIAL]: {
        [NSW_STATE]: {
            infoStatement: {
                value: 'info_statement',
                label: 'Tenant info statement'
            },
            tenantChecklist: {
                value: 'tenant_checklist',
                label: 'Tenant checklist'
            },
            addendumTenantChecklist: {
                value: 'addendum_tenant_checklist',
                label: 'Addendum Tenant checklist'
            },
            swimmingPoolCompliance: {
                value: 'swimming_pool_compliance',
                label: 'Swimming pool compliance'
            },
            agencyAgreements: {
                value: 'agency_agreements',
                label: 'Agency agreements'
            },
            strataByLaws: {
                value: 'strata_by-laws',
                label: 'Strata by-laws'
            },
            conditionReport: {
                value: 'condition_report',
                label: 'Condition report'
            },
            other: {
                value: 'other',
                label: 'Other'
            }
        },
        [QLD_STATE]: {
            addendumTenantChecklist: {
                value: 'form-17a',
                label: 'Form 17a'
            },
            swimmingPoolCompliance: {
                value: 'swimming_pool_compliance',
                label: 'Swimming pool compliance'
            },
            strataByLaws: {
                value: 'strata_by-laws',
                label: 'Strata by-laws'
            },
            conditionReport: {
                value: 'condition_report',
                label: 'Condition report'
            },
            other: {
                value: 'other',
                label: 'Other'
            }
        },
        [VIC_STATE]: {
            rentersGuide: {
                value: 'renters_guide',
                label: 'Renters guide'
            },
            swimmingPoolCompliance: {
                value: 'swimming_pool_compliance',
                label: 'Swimming pool compliance'
            },
            ownersCorporationRules: {
                value: 'owners_corporation_rules',
                label: 'Owners corporation rules'
            },
            conditionReport: {
                value: 'condition_report',
                label: 'Condition report'
            },
            other: {
                value: 'other',
                label: 'Other'
            }
        },
        [ACT_STATE]: {
            infoStatement: {
                value: 'info_statement',
                label: 'Tenant info statement'
            },
            tenantChecklist: {
                value: 'tenant_checklist',
                label: 'Tenant checklist'
            },
            addendumTenantChecklist: {
                value: 'addendum_tenant_checklist',
                label: 'Addendum Tenant checklist'
            },
            swimmingPoolCompliance: {
                value: 'swimming_pool_compliance',
                label: 'Swimming pool compliance'
            },
            unitOrTownhouse: {
                value: 'unit_or_townhouse',
                label: 'Unit title rental certificate'
            },
            agencyAgreements: {
                value: 'agency_agreements',
                label: 'Agency agreements'
            },
            strataByLaws: {
                value: 'strata_by-laws',
                label: 'Strata by-laws'
            },
            conditionReport: {
                value: 'condition_report',
                label: 'Condition report'
            },
            other: {
                value: 'other',
                label: 'Other'
            }
        },
        [SA_STATE]: {
            swimmingPoolCompliance: {
                value: 'swimming_pool_compliance',
                label: 'Swimming pool compliance'
            },
            strataByLaws: {
                value: 'strata_by-laws',
                label: 'Strata by-laws'
            },
            conditionReport: {
                value: 'condition_report',
                label: 'Condition report'
            },
            other: {
                value: 'other',
                label: 'Other'
            }
        }
    },
    [LEASE_TYPE_PROPERTY_MANAGEMENT]: {
        [NSW_STATE]: {},
        [QLD_STATE]: {
            housingStandard: {
                value: 'fact_sheet',
                label: 'Fact sheet'
            }
        },
        [VIC_STATE]: {},
        [SA_STATE]: {
            marketingSchedule: {
                value: 'marketing_schedule',
                label: 'Marketing schedule'
            },
            other: {
                value: 'other',
                label: 'Other'
            }
        }
    },
    [TEMPLATE_TYPE_SALES]: {
        [NSW_STATE]: {
            infoStatement: {
                value: 'info_statement',
                label: 'Tenant info statement'
            },
            tenantChecklist: {
                value: 'tenant_checklist',
                label: 'Tenant checklist'
            },
            addendumTenantChecklist: {
                value: 'addendum_tenant_checklist',
                label: 'Addendum Tenant checklist'
            },
            swimmingPoolCompliance: {
                value: 'swimming_pool_compliance',
                label: 'Swimming pool compliance'
            },
            agencyAgreements: {
                value: 'agency_agreements',
                label: 'Agency agreements'
            },
            strataByLaws: {
                value: 'strata_by-laws',
                label: 'Strata by-laws'
            },
            conditionReport: {
                value: 'condition_report',
                label: 'Condition report'
            },
            other: {
                value: 'other',
                label: 'Other'
            }
        }
    },
    [TEMPLATE_TYPE_PROPERTY_MANAGEMENT]: {
        [NSW_STATE]: {},
        [QLD_STATE]: {},
        [VIC_STATE]: {},
        [SA_STATE]: {
            marketingSchedule: {
                value: 'marketing_schedule',
                label: 'Marketing schedule'
            },
            other: {
                value: 'other',
                label: 'Other'
            }
        }
    },
    [LEASE_TYPE_COMMERCIAL_LEASE]: {
        [NSW_STATE]: {
            inventory: {
                value: 'inventory',
                label: 'Inventory'
            },
            strataByLaws: {
                value: 'strata_by-laws',
                label: 'Strata by-laws'
            },
            conditionReport: {
                value: 'condition_report',
                label: 'Condition report'
            },
            other: {
                value: 'other',
                label: 'Other'
            }
        }
    },
    [LEASE_TYPE_SALES]: {
        [NSW_STATE]: {
            agencyAgreements: {
                value: 'agency_agreements',
                label: 'Agency agreements'
            },
            other: {
                value: 'other',
                label: 'Other'
            }
        },
        [QLD_STATE]: {
            other: {
                value: 'other',
                label: 'Other'
            }
        }
    }
};

export const defaultTenancyAttachedDocuments = {
    [NSW_STATE]: [
        {
            documentName: 'tenant-info-statement.pdf',
            category: documentCategories[LEASE_TYPE_RESIDENTIAL][NSW_STATE].infoStatement.value,
            fileName: 'NSWFT_Tenant_Info_Statement.pdf' // api fileName
        }
    ],
    [QLD_STATE]: [
        {
            documentName: 'form-17a.pdf',
            category: documentCategories[LEASE_TYPE_RESIDENTIAL][QLD_STATE].addendumTenantChecklist.value,
            fileName: 'Form-17a-QLD.pdf' // api fileName
        }
    ],
    [VIC_STATE]: [
        {
            documentName: 'renters-guide.pdf',
            category: documentCategories[LEASE_TYPE_RESIDENTIAL][VIC_STATE].rentersGuide.value,
            fileName: 'renting-a-home-a-guide-for-tenants.pdf' // api fileName
        }
    ],
    [ACT_STATE]: [
        {
            documentName: 'renting_book_2024.pdf',
            category: documentCategories[LEASE_TYPE_RESIDENTIAL][ACT_STATE].infoStatement.value,
            fileName: 'Renting_Book_2024.pdf' // api fileName
        },
        {
            id: 'SWIMMING_POOL_COMPLIANCE_PDF',
            documentName: 'guidance-material-notified-under-the-building-act-2004.pdf',
            category: documentCategories[LEASE_TYPE_RESIDENTIAL][ACT_STATE].swimmingPoolCompliance.value,
            fileName: 'guidance-material-notified-under-the-building-act-2004-2024-215.pdf' // api fileName
        }
    ],
    [SA_STATE]: [
        {
            documentName: 'Residential-tenancies-Information-brochure.pdf',
            category: 'residential-tenancies-act',
            fileName: 'Residential-tenancies-Information-brochure.pdf' // api fileName
        }
    ]
};

export const defaultSalesAttachedDocuments = {
    [NSW_STATE]: [
        {
            documentName: 'Fact-sheets-agency-agreements.pdf',
            category: documentCategories[LEASE_TYPE_SALES][NSW_STATE].agencyAgreements.value,
            fileName: 'Fact-sheets-agency-agreements.pdf' // api fileName
        }
    ],
    [QLD_STATE]: [
        {
            documentName: 'Property Smart Guide to Selling Propery in QLD.pdf',
            category: 'guide_to_selling_property_in_QLD',
            fileName: 'Property-Smart-Guide-to-Selling-Property-in-QLD.pdf' // api fileName
        }
    ],
    [VIC_STATE]: []
};

export const defaultPmAttachedDocuments = {
    [NSW_STATE]: [],
    [QLD_STATE]: [
        {
            documentName: 'Fact-sheet-minimum-housing-standards-general-tenancies.pdf',
            category: documentCategories[LEASE_TYPE_PROPERTY_MANAGEMENT][QLD_STATE].housingStandard.value,
            fileName: 'Fact-sheet-minimum-housing-standards-general-tenancies.pdf' // api fileName
        }
    ],
    [VIC_STATE]: [],
    [SA_STATE]: []
};

export const defaultCommercialLeaseAttachedDocuments = {
    [NSW_STATE]: []
};

export function viewUploadedDocument(leaseId, docId) {
    window.open(`/reader/view-document/${leaseId}/${docId}`, '_blank');
}

export function viewUploadedDocumentFromDocument(documentId, docId) {
    window.open(`/reader/view-uploaded-document/${documentId}/${docId}`, '_blank');
}

export function viewUploadedAgencyDocument(agencyId, docId) {
    window.open(`/reader/view-default-agency-document/${agencyId}/${docId}`, '_blank');
}

export function formatUrl(url) {
    if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1) {
        return `https://${url}`;
    }
    return url;
}

export function viewDefaultDocument(state, docName) {
    window.open(`/reader/default-document/${state}/${docName}/`, '_blank');
}

/**
 * Return a list of Objects, containing available locations
 * based on the leaseType.
 *
 * leaseType param is passed in two formats one for get states based on lease type and the other is get all.
 * if parameter is empty then it return all states. all states is same as RESIDENTIAL states.
 *
 * @param {String} leaseType this is optional. if this is empty then this will return all states
 *
 * @return {Object} Array of Objects containing available locations.
 */
export function getAvailableStates(leaseType: string | boolean = false): object {
    const lowerCaseLeaseType = leaseType ? leaseType.replace(/\s+/g, '_').toLowerCase() : '';
    if (availableStates[lowerCaseLeaseType]) {
        return availableStates[lowerCaseLeaseType].states;
    }
    return availableStates.default.states;
}
/**
 * Return a Boolean, whether payment method is available or not
 * based on the method and leaseType.
 *
 * @param {String} method
 * @param {String} leaseType
 * @param {String} location
 * @return {Boolean} method is available or not.
 */
export function isPaymentMethodAvailable(method, leaseType, location = NSW_STATE) {
    const type = leaseType.replace(/\s+/g, '_').toLowerCase();
    if (availablePaymentMethods[type] && availablePaymentMethods[type][location]) {
        return availablePaymentMethods[type][location].methods.includes(method);
    }
    return false;
}

export const services = [
    {
        title: 'Gas',
        icon: 'gas',
        inputName: 'gas'
    },
    {
        title: 'Electricity',
        icon: 'electricity',
        inputName: 'electricity'
    },
    {
        title: 'Internet',
        icon: 'internet',
        inputName: 'internet'
    },
    {
        title: 'Telephone',
        icon: 'telephone',
        inputName: 'telephone'
    },
    {
        title: 'Pay Tv',
        icon: 'pay-tv',
        inputName: 'payTV'
    },
    {
        title: 'Ute or truck hire',
        icon: 'vehicleHire',
        inputName: 'vehicleHire'
    },
    {
        title: 'Water (VIC Only)',
        icon: 'water',
        inputName: 'water'
    }
];

export const qldDefaultServices = [
    {
        name: 'Electricity',
        locked: true
    },
    {
        name: 'Gas',
        locked: true
    },
    {
        name: 'Phone',
        locked: true
    }
];

export const leaseTerminationReasonOptions = {
    [NSW_STATE]: [
        { value: 'end_fixed', label: 'End fixed term' },
        { value: 'periodic_tenancy', label: 'End periodic agreement' },
        { value: 'sold', label: 'Premises is being sold' },
        { value: 'breach', label: 'Breach of agreement' },
        { value: 'none_payment', label: 'Non payment of rent' },
        { value: 'death', label: 'Death of the tenant' },
        { value: 'uninhabitable', label: 'Premises destroyed or wholly or partly uninhabitable' },
        { value: 'unuseable', label: 'Cease to be useable as a residence' },
        { value: 'acquired', label: 'Appropriated/acquired by authority by compulsory process' }
    ],
    [QLD_STATE]: [
        { value: 'without_grounds', label: 'Without Grounds' },
        { value: 'with_grounds', label: 'With Grounds' }
    ]
};

/**
 * Adapted from https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
 * @returns true if the primary input device is a touch screen
 */
export function isTouchScreen(): boolean {
    const { maxTouchPoints, userAgent } = navigator;

    if (maxTouchPoints) {
        return maxTouchPoints > 0;
    } else {
        const { msMaxTouchPoints } = navigator as unknown as { msMaxTouchPoints: number };

        if (msMaxTouchPoints) {
            return msMaxTouchPoints > 0;
        }
    }
    const mQ = window.matchMedia?.('(pointer:coarse)');

    if (mQ?.media === '(pointer:coarse)') {
        return !!mQ.matches;
    } else if ('orientation' in window) {
        return true; // deprecated, but good fallback
    } else {
        // Only as a last resort, fall back to user agent sniffing
        const UA = userAgent;
        return /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
}

export function isMobileWidth(breakPoint?: number) {
    const width = window.innerWidth > 0 ? window.innerWidth : screen.width;
    if (breakPoint) {
        return width <= breakPoint;
    }
    return width <= MOBILE_WIDTH;
}

export const DEFAULT_NUMBER_OF_WEEKS_FOR_PROPERTY_MANAGEMENT_BOND = 4;

export const OPTION_TYPE_YES_OR_NO = 'yesOrNo';
export const OPTION_TYPE_AGREE_ONLY = 'agreeOnly';
export const specialCOnditionsOptionTypesList = [
    {
        label: 'Yes/No',
        value: OPTION_TYPE_YES_OR_NO
    },
    {
        label: 'Agree',
        value: OPTION_TYPE_AGREE_ONLY
    }
];
export const STATUS_ACTIVE = 'active';
export const STATUS_DRAFT = 'draft';

export const defaultPage = 1;
export const defaultRecordsPerPage = 9;

export const PARTY_AGENT = 'agent';
export const PARTY_LANDLORD = 'landlord';
export const PARTY_NONE = 'none';
export const DETAILS_OPTION_OPTIONAL = 'details_optional';
export const DETAILS_OPTION_REQUIRED = 'details_required';
export const DETAILS_OPTION_NO_DETAILS = 'no_details';

export const SALE_METHOD_PRIVATE_TREATY = 'Private Treaty';
export const SALE_METHOD_AUCTION = 'Auction';

export const CLOSE_MODAL_MESSAGE = 'You have un-saved changes, do you want to save before you exit?';

export const CLIENT_AGENT = 'Agent';
export const CLIENT_LANDLORD = 'Landlord';
export const CLIENT_TENANT = 'Tenant';
export const CLIENT_VENDOR = 'Vendor';
export const CLIENT_CUSTOM = 'Custom';
export const CLIENT_TRADING_NAME = 'Trading Name';
export const CLIENT_OTHER = 'Other';

export const CLIENT_TYPE_FROM = 'from';
export const CLIENT_TYPE_TO = 'to';

export const ANSWER_YES = 'Yes';
export const ANSWER_NO = 'No';
export const ANSWER_NA = 'N/A';
export const ANSWER_NOT_KNOWN = 'Not known';
export const ANSWER_DETAILS = 'Details';
export const ANSWER_FILE = 'File';

export const defaultAnswerOptions = [
    {
        answer: ANSWER_YES,
        detailsOption: DETAILS_OPTION_OPTIONAL,
        name: 'yes'
    },
    {
        answer: ANSWER_NO,
        name: 'no'
    },
    {
        answer: ANSWER_NA,
        name: 'n/a'
    },
    {
        answer: ANSWER_NOT_KNOWN,
        name: 'not_known'
    },
    {
        answer: ANSWER_DETAILS,
        name: 'details',
        detailsOption: DETAILS_OPTION_REQUIRED
    },
    {
        answer: ANSWER_FILE,
        name: 'file',
        detailsOption: DETAILS_OPTION_OPTIONAL,
        uploadFile: true
    }
];

export const clientDefaultFields = [
    'Mobile',
    'Agency Email',
    'Email',
    'Agency Name',
    'First Name',
    'Middle Name',
    'Last Name',
    'Corporation Name',
    'Type',
    'Client Title',
    'Sender Title'
];

export const FLK_TEMPLATE_LABEL = 'Templates provided by FLK';

export const FORM_SUBMIT_TYPE_SEND = 'send';
export const FORM_SUBMIT_TYPE_SAVE = 'save';

export enum FormSubmitType {
    Send = FORM_SUBMIT_TYPE_SEND,
    Save = FORM_SUBMIT_TYPE_SAVE,
    Create = 'create'
}

export const COMPLETION_STATUS_STARTED = 'started';
export const COMPLETION_STATUS_ERROR = 'error';
export const COMPLETION_STATUS_PROCESSING = 'processing';

export enum CompletionStatus {
    Started = COMPLETION_STATUS_STARTED,
    Error = COMPLETION_STATUS_ERROR,
    Processing = COMPLETION_STATUS_PROCESSING
}

export const breachAgreementClauseOptions = {
    [NSW_STATE]: [
        {
            value: 'claues_16',
            label: 'Clauses 16 & 17: Use of the premises by tenant',
            index: 0,
            text: 'Clause 16  - The tenant agrees:\n16.1: not to use the residential premises, or cause or permit the premises to be used, for any illegal purpose, and\n16.2: not to cause or permit a nuisance, and\n16.3: not to interfere, or cause or permit interference, with the reasonable peace, comfort or privacy of neighbours, and\n16.4: not to intentionally or negligently cause or permit any damage to the residential premises, and\n16.5: not to cause or permit more people to reside in the residential premises than is permitted by this agreement.\nClause 17  - The tenant agrees:\n17.1: to keep the residential premises reasonably clean, and\n17.2: to notify the landlord as soon as practicable of any damage to the residential premises, and\n17.3: that the tenant is responsible to the landlord for any act or omission by a person who is lawfully on the residential premises if the person is only permitted on the premises with the tenant’s consent and the act or omission would be in breach of this agreement if done or omitted by the tenant, and\n17.4: that it is the tenant’s responsibility to replace light globes on the residential premises.'
        },
        {
            value: 'clause_22',
            label: 'Clause 22: Sale of Premises',
            index: 1,
            text: 'The tenant agrees not to unreasonably refuse to agree to days and times when the residential premises are to be available for inspection by potential purchasers.'
        },
        {
            value: 'clause_27',
            label: 'Clause 27: Landlord’s access to the Premises',
            index: 2,
            text: 'The tenant agrees to give access to the residential premises to the landlord, the landlord’s agent or any person, if they are exercising a right to enter the residential premises in accordance with this agreement'
        },
        {
            value: 'clause_30',
            label: 'Clause 30: Fixtures, Alterations, additions or renovations to the premises',
            index: 3,
            text: 'The tenant agrees:\n30.1: not to install any fixture or renovate, alter or add to the residential premises without the landlord’s written permission, and\n30.2: that certain kinds of fixtures or alterations, additions or renovations that are of a minor nature specified by clause 22(2) of the Residential Tenancies Regulation 2019 may only be carried out by a person appropriately qualified to carry out those alterations unless the landlord gives consent, and\n30.3: to pay the cost of a fixture, installed by or on behalf of the tenant, or any renovation, alteration or addition to the residential premises, unless the landlord otherwise agrees, and\n30.4: not to remove, without the landlord’s permission, any fixture attached by the tenant that was paid for by the landlord or for which the landlord gave the tenant a benefit equivalent to the cost of the fixture, and\n30.5: to notify the landlord of any damage caused by removing any fixture attached by the tenant, and\n30.6: to repair any damage caused by removing the fixture or compensate the landlord for the reasonable cost of repair.'
        },
        {
            value: 'clause_56',
            label: 'Clause 56: Pets',
            index: 4,
            text: 'The tenant agrees not to keep animals on the residential premises without obtaining the landlord’s consent.'
        },
        {
            value: 'clause_60',
            label: 'Clauses 60 & 61: Smoking',
            index: 5,
            text: '60: The tenant must not smoke or allow others to smoke in the premises.\n61: If the tenant smokes or allows others to smoke outside the premises, the tenant must ensure that all cigarette butts are properly disposed and not left on the ground.'
        },
        {
            value: 'clause_76',
            label: 'Clause 76: Gardens',
            index: 6,
            text: '76: The tenant is responsible for regularly maintaining the yard and gardens on the premises (including regular mowing, edging, pruning and weeding) during the tenancy period. The tenant agrees to keep the yard and gardens on the premises in good condition (having regard to the condition report) during the tenancy period, fair wear and tear excluded.'
        },
        {
            value: 'clause_77',
            label: 'Clause 77: Swimming Pool',
            index: 7,
            text: '77: If there is a swimming pool located on the premises, the tenant must:\n77.1: keep the swimming pool clean and regularly sweep up any leaves or other debris which have fallen into the swimming pool\n77.2: regularly clean the sides of the swimming pool to minimise build-up of slime and other residue\n77.3: regularly clean the pool filters and empty out the leaf baskets\n77.4: maintain the cleanliness and clarity of the water to a standard set by the landlord (acting reasonably) by testing the pool water monthly and treating, at the tenant’s cost, the pool with the necessary chemicals, if required\n77.5: maintain the water level above the filter inlet at all times\n77.6: promptly notify the landlord or the landlord’s agent of any issues with the pool or pool equipment\n77.7: ensure that all doors and gates providing access to the swimming pool are kept securely closed at all times when they are not in actual use\n77.8: not leave any items near the swimming pool or the safety door/gate which would allow a child to gain access to the swimming pool; an\n77.9: take all reasonable steps to ensure no unaccompanied child can gain access to the pool area.'
        },
        {
            value: 'other',
            label: 'Other',
            index: 8,
            text: ''
        }
    ],
    [QLD_STATE]: [
        {
            value: 'non_payment_of_rent',
            label: 'Non-payment of Rent (including non payment of water or other utilities)',
            index: 0,
            text: ''
        },
        {
            value: 'using_premises_for_illegal_purpose',
            label: 'Using premises for illegal purpose',
            index: 1,
            text: ''
        },
        {
            value: 'keeping_pet_without_permission',
            label: 'Keeping a pet without permission',
            index: 2,
            text: ''
        },
        {
            value: 'more_tenants_in_property_than_stated_on_agreement',
            label: 'More tenants in property than stated on agreement',
            index: 3,
            text: ''
        },
        {
            value: 'not_keeping_the_property_in_good_condition',
            label: 'Not keeping the property in good condition',
            index: 4,
            text: ''
        },
        {
            value: 'local_government_laws_not_met',
            label: 'Local government laws not met (i.e. hazardous material etc)',
            index: 5,
            text: ''
        },
        {
            value: 'use_of_property_causes_a_nuisance_to_neighbours',
            label: 'Use of property causes a nuisance to neighbours',
            index: 6,
            text: ''
        },
        {
            value: 'ongoing_neighbour_disputes',
            label: 'Ongoing neighbour disputes',
            index: 7,
            text: ''
        },
        {
            value: 'other',
            label: 'Other',
            index: 8,
            text: ''
        }
    ]
};
export const DELIVERY_TYPE_EMAIL = 'email';
export const DELIVERY_TYPE_HAND = 'hand';
export const DELIVERY_TYPE_POST = 'post';
export const DELIVERY_TYPE_IN_PERSON = 'in_person ';
export const DELIVERY_TYPE_OVER_16 = 'to_someone_over_16';
export const DELIVERY_TYPE_LETTERBOX = 'letterbox';
export const DELIVERY_TYPE_OTHER = 'other';
export const DELIVERY_TO_AGENT = 'agentOnly';
export const DELIVERY_TO_AGENT_AND_TENANT = 'agentAndTenant';
export const DELIVERY_TO_AGENT_AND_VENDOR = 'agentAndVendor';

export const DOCUMENT_COMPLETION_ERROR = 'error';
export const DOCUMENT_COMPLETION_FINISHED = 'finished';

export const corporationRolesList = [
    { label: 'Director', value: 'Director' },
    { label: 'Secretary ', value: 'Secretary' },
    { label: 'Other', value: 'Other' }
];

export const leseeCorporationRolesList = [
    { label: 'Director', value: 'Director' },
    { label: 'Secretary ', value: 'Secretary' },
    { label: 'Sole Director', value: 'Sole Director' },
    { label: 'Sole Director/Secretary', value: 'Sole Director/Secretary' },
    { label: 'Director/Secretary', value: 'Director/Secretary' },
    { label: 'Other', value: 'Other' }
];

export const lessorCorporationRolesList = [
    { label: 'Director', value: 'Director' },
    { label: 'Director/Secretary ', value: 'Director/Secretary' },
    { label: 'Other', value: 'Other' }
];

export const DOCUMENT_DELIVERY_TYPE_SMS = 'sms';
export const DOCUMENT_DELIVERY_TYPE_EMAIL = 'email';
export const DOCUMENT_DELIVERY_TYPE_QR_CODE = 'qr_code';

export function getDeliveryTypeText(deliveryType: string) {
    switch (deliveryType) {
        case DOCUMENT_DELIVERY_TYPE_SMS:
            return 'SMS';
        case DOCUMENT_DELIVERY_TYPE_EMAIL:
            return 'Email';
        case DOCUMENT_DELIVERY_TYPE_QR_CODE:
            return 'QR code / Link';
        default:
            return '-';
    }
}

export const CLIENT_FIELD_LABEL_MOBILE = 'Mobile';

export const SELECT_OPTION_OTHER = 'Other';

export const contactTypeList = [
    { value: 'Plumber', label: 'Plumber' },
    { value: 'Electrician', label: 'Electrician' },
    { value: 'Cleaner', label: 'Cleaner' },
    { value: 'Tenant', label: 'Tenant' },
    { value: 'Owner', label: 'Owner' },
    { value: 'Builder / Handy person', label: 'Builder / Handy person' },
    { value: SELECT_OPTION_OTHER, label: SELECT_OPTION_OTHER }
];
export const reasonForLoanList = [
    { value: 'Maintenance', label: 'Maintenance' },
    { value: 'Smoke Alarms', label: 'Smoke Alarms' },
    { value: 'Tenant Locked out', label: 'Tenant Locked out' },
    { value: 'Sales inspection', label: 'Sales inspection' },
    { value: 'Cleaning', label: 'Cleaning' },
    { value: SELECT_OPTION_OTHER, label: SELECT_OPTION_OTHER }
];

export const propertyTypeOptions = [
    { value: 'House', label: 'House' },
    { value: 'Duplex', label: 'Duplex' },
    { value: 'Semi-detached', label: 'Semi-detached' },
    { value: 'Unit', label: 'Unit' },
    { value: 'Townhouse', label: 'Townhouse' },
    { value: 'Villa', label: 'Villa' },
    { value: 'Terrace', label: 'Terrace' },
    { value: SELECT_OPTION_OTHER, label: SELECT_OPTION_OTHER }
];

export const DUE_DATE_TODAY = 'today';
export const DUE_DATE_TOMORROW = 'tomorrow';
export const DUE_DATE_END_OF_WEEK = 'end_of_week';

export const CONTACT_PERSON_NOMINATED_TRADES = 'Nominated Trades';
export const CONTACT_PERSON_AGENT = 'Agent';

export const SCHEDULE_TYPE_NOW = 'now';
export const SCHEDULE_TYPE_LATER = 'later';

// For signup page
export const SIGN_UP_PRICING_STEP = 1;
export const SIGN_UP_ACCOUNT_STEP = 2;
export const SIGN_UP_DETAILS_STEP = 3;
export const SIGN_UP_VERIFY_EMAIL_STEP = 4;
export enum SignUpStep {
    Pricing = SIGN_UP_PRICING_STEP,
    Account = SIGN_UP_ACCOUNT_STEP,
    Details = SIGN_UP_DETAILS_STEP,
    VerifyEmail = SIGN_UP_VERIFY_EMAIL_STEP
}
// Total amount of steps for which a form is displayed
export const SIGN_UP_FORM_STEPS_COUNT = 2;
export const SIGN_UP_PRICING_STEP_TITLE = 'Next-gen signing for real estate starts here';
export const SIGN_UP_PRICING_STEP_TITLE_BUSINESS = 'Create, send and sign documents that dazzle.';
export const SIGN_UP_ACCOUNT_STEP_TITLE = "You're about to save 2 full days of admin in your month";
export const SIGN_UP_ACCOUNT_LANDING_PAGE_TITLE = SIGN_UP_PRICING_STEP_TITLE_BUSINESS;
export const SIGN_UP_ACCOUNT_STEP_TITLE_BUSINESS = 'We will use your details to auto-fill any documents you create';
export const SIGN_UP_DETAILS_STEP_TITLE = SIGN_UP_ACCOUNT_STEP_TITLE_BUSINESS;

//Banner Ids
export const BANNER_ID_QLD_LEGISLATION = 'QLD_LEGISLATION';
export const BANNER_ID_2FA = 'TWO_FACTOR_AUTHENTICATION';

export const SEND_STATUS_PENDING = 'pending';
export const SEND_STATUS_SENT = 'sent';
export const SEND_STATUS_ERROR = 'error';

export const REVIEW_METHOD_CPI = 'CPI';
export const REVIEW_METHOD_FIXED_AMOUNT = 'Fixed Amount';
export const REVIEW_METHOD_FIXED_PERCENTAGE = 'Fixed Percentage';
export const REVIEW_METHOD_CURRENT_MARKET_RENT = 'Current Market Rent';

export const ADDITIONAL_RENT_REVIEW_CPI = 'cpi';
export const ADDITIONAL_RENT_REVIEW_PERCENTAGE = 'percentage';
export const ADDITIONAL_OUTGOING_LESSOR = 'lessor';
export const ADDITIONAL_OUTGOING_LESSEE = 'lessee';
export const ADDITIONAL_OUTGOING_LESSEE_PAY_OUTGOINGS = 'pay_outgoings';
export const ADDITIONAL_OUTGOING_LESSEE_PAY_RENT_INCREASE = 'pay_rent_increase';
export const ADDITIONAL_OPTION_OF_RENEWAL_FIXED = 'fixed';
export const ADDITIONAL_OPTION_OF_RENEWAL_CPI = 'cpi';
export const ADDITIONAL_OPTION_OF_RENEWAL_MARKET_RENT = 'marketRent';

export const OUTGOING_HANDLED_AS_FIXED_PERCENTAGE = 'fixed_percentage';
export const OUTGOING_HANDLED_AS_ESTIMATES_PROVIDED = 'estimates_provided';

export const OUTGOING_HANDLED_AS_FIXED_PERCENTAGE_WITHOUT_INCREASES = 'without_increases';
export const OUTGOING_HANDLED_AS_FIXED_PERCENTAGE_WITH_INCREASES = 'with_increases';

export const CONTACT_TYPE_AUTO_SAVE = 'auto_save';

export const TWO_FACTOR_AUTHENTICATION_SMS = 'sms';
export const TWO_FACTOR_AUTHENTICATION_EMAIL = 'email';
export const TWO_FACTOR_AUTHENTICATION_APP = 'totp';

export const SEND_TYPE_EMAIL = 'Email';
export const SEND_TYPE_POST = 'Post';

export const OUTGOING_AGENT_TO_PAY = 'Agent to pay';
export const OUTGOING_OWNER_TO_INSTRUCT = 'Owner to instruct';
export const OUTGOING_NOT_APPLICABLE = 'Not applicable';

export const imageCompressionOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1024,
    useWebWorker: true
};

export const entryPartyTypes = [
    { label: 'Agent', value: 'Agent' },
    { label: 'Landlord', value: 'Landlord' },
    { label: 'Tradesperson', value: 'Tradesperson' },
    { label: 'Strata', value: 'Strata' },
    { label: 'Fire inspector', value: 'Fire inspector' },
    { label: 'Other ', value: 'Other' }
];
export const VERIFICATION_CODE_LIMIT_MESSAGE = "You've tried to resend too many times. Please contact support.";

export const createViaUrlEnabledTypes = [LEASE_TYPE_SALES, LEASE_TYPE_PROPERTY_MANAGEMENT];

export enum countryCodes {
    AU = 'au',
    NZ = 'nz'
}

export enum contactListShareOptions {
    ALL = 'all',
    DOCUMENT_PERMISSION = 'basedOnDocumentPermissionSettings'
}
