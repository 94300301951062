import { INTEGRATION_LABEL, UploadStatus } from '@app/constants/constants';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import {
    uploadDocumentIntegration,
    updateDashboardIntegrationForTerminationNotice,
    updateDashboardIntegrationForIntentionToSellNotice,
    updateDashboardIntegrationForEntryNotice,
    updateDashboardIntegrationForRentIncrease
} from '../../../actions/dashboard.js';
import {
    updateDocumentIntegrationForTerminationNotice,
    updateDocumentIntegrationForIntentionToSellNotice,
    updateDocumentIntegrationForEntryNotice,
    updateDocumentIntegrationForRentIncrease
} from '../../../actions/document.js';
import {
    DOCUMENT_INTENTION_TO_SELL,
    DOCUMENT_TERMINATION_NOTICE,
    ENTRY_NOTICE,
    DOCUMENT_RENT_INCREASE,
    type ValidDocumentTypes
} from '@app/config';

export type AgreementIntegrationType = {
    integration: {
        id: string;
        address: string;
        type: keyof typeof INTEGRATION_LABEL;
        active: boolean;
        lastSuccessfulUpload: string;
        uploadStatus: UploadStatus;
    };
};

type AgreementIdType = { docId: string };
type AgreementType = AgreementIdType & { documentType: 'lease' | ValidDocumentTypes };
type AgreementMutationParams = AgreementIdType & { integrationUploadUrl: string }

const useIntegrationUploadDocuments = () => {
    const dispatch = useDispatch();

    const getUploadStatus = ({ integration }: AgreementIntegrationType) => {
        return integration?.uploadStatus ?? UploadStatus.DEFAULT;
    };

    const integrationUploadDocumentsMutation = useMutation({
        mutationFn: async ({ docId, integrationUploadUrl }: AgreementMutationParams) => {
            if (!docId) {
                throw new Error(
                    'Required parameters are missing or invalid. Please check the upload document request.'
                );
            }
            const response = await axios.post(integrationUploadUrl);
            return response.data;
        }
    });

    const handleUploadClick = ({ docId, documentType }: AgreementType) => {
        if (!docId) {
            return;
        }

        if (documentType === DOCUMENT_TERMINATION_NOTICE) {
            dispatch(updateDocumentIntegrationForTerminationNotice(docId));
            dispatch(updateDashboardIntegrationForTerminationNotice(docId));

            const integrationUploadUrl = `/api/document/termination-notice/${docId}/integration-upload`;
            integrationUploadDocumentsMutation.mutate({ docId, integrationUploadUrl });
        } else if (documentType === DOCUMENT_INTENTION_TO_SELL) {
            dispatch(updateDocumentIntegrationForIntentionToSellNotice(docId));
            dispatch(updateDashboardIntegrationForIntentionToSellNotice(docId));

            const integrationUploadUrl = `/api/document/intention-to-sell-notice/${docId}/integration-upload`;
            integrationUploadDocumentsMutation.mutate({ docId, integrationUploadUrl });
        } else if (documentType === ENTRY_NOTICE) {
            dispatch(updateDocumentIntegrationForEntryNotice(docId));
            dispatch(updateDashboardIntegrationForEntryNotice(docId));

            const integrationUploadUrl = `/api/document/entry-notice/${docId}/integration-upload`;
            integrationUploadDocumentsMutation.mutate({ docId, integrationUploadUrl });
        } else if (documentType === DOCUMENT_RENT_INCREASE) {
            dispatch(updateDocumentIntegrationForRentIncrease(docId));
            dispatch(updateDashboardIntegrationForRentIncrease(docId));

            const integrationUploadUrl = `/api/document/rent-increase/${docId}/integration-upload`;
            integrationUploadDocumentsMutation.mutate({ docId, integrationUploadUrl });
        } else {
            dispatch(uploadDocumentIntegration(docId));

            const integrationUploadUrl = `/api/agency/lease/${docId}/integration-upload`;
            integrationUploadDocumentsMutation.mutate({ docId, integrationUploadUrl });
        }
    };

    return { getUploadStatus, handleUploadClick };
};

export default useIntegrationUploadDocuments;
